<template>
    <div class="canvass">
        <div class="banner">
            <Swipe :initial-swipe="current" :duration="500" :autoplay="5000" :loop="true" :show-indicators="true" indicator-color="#e93323">
                    <SwipeItem v-for="(item, index) in info.slider_image" :key="index">
                        <div class="dpic">
                            <VanImage width="100%" height="100%" lazy-load fit="cover" :src="item" />
                        </div>
                    </SwipeItem>
                </Swipe>
        </div>
        <div class="info">
            <div class="product-info">
                <div class="name">{{info.store_name}}全国招商</div>
                <div class="res">申请免费拿样 | 联系咨询合作报价</div>
            </div>
            <div class="eq-code" v-if="info.eq_code">
                <div class="eq-text">识别二维码添加微信</div>
                <div class="xian-box">
                    <div class="xian"></div>
                    <div class="bai"></div>
                </div>
                <div class="code">
                    <img :src="info.eq_code" />
                </div>
            </div>
        </div>
        <div class="img">
            <img src="@assets/images/canvass1.jpg" />
        </div>
        <div class="subform">
            <input class="name in" type="text" placeholder="姓名" v-model="canvassInfo.real_name"  />
            <div class="phone-box">
                <input class="phone in" type="number" placeholder="手机号码" v-model="canvassInfo.phone"  />
                <!-- <div class="message">一键填写</div> -->
            </div>
            <div class="agree">
                <div class="checkbox-wrapper">
                    <label class="well-check"
                    ><input
                        type="radio"
                        name="radio0"
                        value=""
                        :checked="canvassInfo.radio_auto ? true : false"
                        @click="radioChange()"
                    /><i class="icon"></i><span class="fontcol">下次自动填写，阅读并同意</span><span @click="goPages('/Tasting/business/agreement?id=4')">《自动填写信息授权书》</span></label
                    >
                </div>
                <div class="checkbox-wrapper">
                    <label class="well-check"
                    ><input
                        type="radio"
                        name="radio1"
                        value=""
                        :checked="canvassInfo.radio_xieyi ? true : false"
                        @click="radioChange1()"
                    /><i class="icon"></i><span class="fontcol">已阅读并同意</span><span @click="goPages('/Tasting/business/agreement?id=5')" >《个人信息与隐私保护条款》</span></label
                    >
                </div>
                <!-- <RadioGroup v-model="canvassInfo.radio0" @change="changeReport($event)" direction="horizontal" checked-color="#e93323">
                    <Radio name="1">下次自动填写，阅读并同意</Radio><span>《自动填写信息授权书》</span>
                </RadioGroup> -->
                <!-- <RadioGroup v-model="canvassInfo.radio1" direction="horizontal" checked-color="#e93323">
                    <Radio name="1">已阅读并同意</Radio><span>《个人信息与隐私保护条款》</span>
                </RadioGroup> -->
            </div>
        </div>
        <div class="submit" @click="submit()">提交意向，马上联系你</div>

        <div class="res-info">
            <div class="ri-s">最近有<span class="sp">{{info.count}}</span>个人领取优惠</div>
            <div class="scroll" v-if="info.list" >
                <div class="scroll-item">
                    <div class="ri"  v-for="(item, index) in info.list" :key="index">{{item.res}}</div>
                </div>
            </div>
            <div class="scroll" v-else >
                <div class="scroll-item">
                    <div class="ri" >用户 187****8533<span style="margin-left:0.1rem">已领取优惠</span><span style="margin-left:0.2rem">1小时前</span></div>
                    <div class="ri" >用户 176****3333<span style="margin-left:0.1rem">已领取优惠</span><span style="margin-left:0.2rem">1小时前</span></div>
                    <div class="ri" >用户 189****1234<span style="margin-left:0.1rem">已领取优惠</span><span style="margin-left:0.2rem">1小时前</span></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {canvassInfo,submitCanvassBusiness} from "@api/tasting";
import { Icon, RadioGroup, Radio, Field, Col, Row, Swipe, SwipeItem ,Image as VanImage} from "vant";
export default {
  name: "canvass",
  data: function() {
    return {
        id:0,
        uid:0,
        canvassInfo:{
            real_name:'',
            phone:'',
            radio_auto:0,
            radio_xieyi:0,
        },
        info:{

        }
    };
  },
  components: {
            Icon, RadioGroup, Radio, Field, Col, Row, Swipe, SwipeItem,VanImage
   },
    created() {
        this.id=this.$route.query.id;
        this.uid=this.$route.query.uid;
    },
  mounted: function() {
      this.getCanvassInfo();
  },
  methods: {
      getCanvassInfo:function(){
        canvassInfo({
            product_id:this.id,
            uid:this.uid,
        }).then(res=>{
            this.info = res.data;
        })
      },
        radioChange: function() {
          if(this.canvassInfo.radio_auto){
              this.canvassInfo.radio_auto = 0
          }else{
              this.canvassInfo.radio_auto = 1
          }
        },
        radioChange1: function() {
          if(this.canvassInfo.radio_xieyi){
              this.canvassInfo.radio_xieyi = 0
          }else{
              this.canvassInfo.radio_xieyi = 1
          }
        },
        goPages: function(url) {
            this.$router.push({ path: url });
        },
        submit:function(){
            if(this.canvassInfo.real_name==''){
                return this.$dialog.toast({ mes:"请填写姓名"});
            }
            if(this.canvassInfo.phone==''){
                return this.$dialog.toast({ mes:"请填写手机号"});
            }
            if (!/^1(3|4|5|7|8|9|6)\d{9}$/.test(this.canvassInfo.phone)) {
                return this.$dialog.toast({ mes: "请填写正确的手机号" });
            }
            // if (!/^[\u4e00-\u9fa5\w]{2,16}$/.test(this.canvassInfo.real_name)) {
            //     return this.$dialog.toast({ mes: "请填写您的真实姓名" });
            // }
            if(!this.canvassInfo.radio_xieyi){
                return this.$dialog.toast({mes:"请勾选《个人信息与隐私保护条款》"});
            }
            this.$dialog.loading.open('提交中');
            submitCanvassBusiness({
                canvassInfo:this.canvassInfo,
                uid:this.uid,
                product_id:this.id,
            }).then(res=>{
                this.$dialog.loading.close();
                this.$dialog.toast({ mes: res.msg});
            }).catch(err => {
                this.$dialog.loading.close();
                this.$dialog.error(err.msg || "提交失败");
            })
        }

  }
};
</script>
<style scoped lang="scss">
.canvass{
    width: 100%;
    .banner{
        position:relative;
        .van-swipe{height: 8rem;}
        .dpic{position:absolute;top:0;height:8rem;background:#fff;}
    }
    .img{
        img{
            width: 100%;
        }
    }
    .info{
        .product-info{
            border-bottom: 2px solid #4b2014;
            padding: 0.4rem 0;
            text-align: center;
            letter-spacing: 0.06rem;
            .name{
                color: rgb(206, 4, 4);
                font-weight: 600;
                font-size: 0.5rem;
                
            }
            .res{
                color: #f00;
                font-size: 0.31rem;
            }
        }
        .eq-code{
            text-align: center;
            .eq-text{
                width: 6.4rem;
                padding: 0.1rem 1rem;
                margin: 0.1rem 0;
                border: 1px solid #444;
                border-radius: 0.8rem;
                font-size: 0.3rem;
                color: rgb(206, 4, 4);
                font-weight: 600;
                position: relative;
                left: -3.2rem;
                margin-left: 50%;
                letter-spacing: 0.06rem;
            }
            .xian-box{
                .xian{
                    width: 6.4rem;
                    border: 1px solid #e5e5e5;
                    border-radius: 0.8rem;
                    padding: 0.2rem;
                    position: relative;
                    left: -3.2rem;
                    margin-left: 50%;
                }
                .bai{
                    width: 100%;
                    padding: 0.2rem;
                    position: relative;
                    top: -0.2rem;
                    background-color: #fff
                }
            }
            
            .code{
                margin-bottom: 0.4rem;
                img{
                    width: 2.6rem;
                }
                
            }
        }
        
    }
    .subform{
        padding: 0.2rem;
        text-align: center;
        .in{
            background-color: #dfdfdf;
            width: 6rem;
            padding: 0.2rem;
            margin: 0.2rem;
            
        }
        
        .phone-box{
            position: relative;
            .phone{
                
            }
            .message{
                position: absolute;
                top: 0.36rem;
                right: 0.8rem;
                color: #5ab248;
            }
        }
        .agree{
            font-size: 0.23rem;
            color: #657ca7;
            margin-left: 0.5rem;
            // .van-radio-group{
            //     margin: 0.2rem 0;
            // }
            .fontcol{
                color: #000;
                margin-left: 0.5rem;
            }
            .checkbox-wrapper{
                margin: 0.2rem 0;
                text-align: left;
            }
        }
    }
    .submit{
        color: #fff;
        background-color: #f00;
        padding: 0.2rem;
        font-size: 0.4rem;
        font-weight: 600;
        text-align: center;
        margin: 0.3rem 0.6rem;
        border-radius: 0.1rem;
    }
    .res-info{
        text-align: center;
        color: #6f7777;
        margin-top: 0.7rem;
        height: 2rem;
        .ri-s{
            margin: 0.4rem 0;
            .sp{
                color:#5ab248;margin:0 0.1rem;font-size: 0.3rem;font-weight: 600;
            }
        }
        .ri{
            margin: 0.2rem 0;
            
        }
        .scroll {
            height: 0.32rem;
            overflow: hidden;
            .scroll-item{
                animation: anis 10s linear infinite;
            }
        }
    
    
}
}
@keyframes anis {
  0% {
    opacity: 1;
    transform: translateY(20%)
  }

  40% {
    opacity: 1;
    transform: translateY(-40%)
  }

  97% {
    transform: translateY(-100%);
  }

  98% {
    opacity: 1;
    transform: translateY(-100%)
  }

  99% {
    opacity: 0;
    transform: translateY(-100%)
  }

  100% {
    opacity: 0;
    transform: translateY(20%)
  }
}

</style>